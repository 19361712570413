var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('article',{staticClass:"course-editor-content__chapters-container"},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[(!_vm.isEditing)?_c('p',{staticClass:"course-editor-content__chapters-paragraph"},[_vm._v(" "+_vm._s(_vm.content)+" ")]):_c('div',[_c('InputTextArea',{model:{value:(_vm.newContent),callback:function ($$v) {_vm.newContent=$$v},expression:"newContent"}}),_c('v-btn',{staticClass:"admin-primary-button",attrs:{"loading":_vm.isLoading},on:{"click":_vm.submit}},[_vm._v("Accept")])],1)]),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex flex-row justify-end"},[_c('span',[_c('v-icon',{staticClass:"text-dark",on:{"click":function($event){return _vm.$emit('changeIndex', {
									type: 'response',
									mark: -1,
									content: _vm.response.content,
									questionId: _vm.response.question_id,
									sort: _vm.response.sort_order,
									responseId: _vm.responseId
								})}}},[_vm._v("mdi-chevron-up")])],1),_c('span',[_c('v-icon',{staticClass:"text-dark",on:{"click":function($event){return _vm.$emit('changeIndex', {
									type: 'response',
									mark: 1,
									content: _vm.response.content,
									questionId: _vm.response.question_id,
									sort: _vm.response.sort_order,
									responseId: _vm.responseId
								})}}},[_vm._v("mdi-chevron-down")])],1),_c('span',[(!_vm.isEditing)?_c('img',{staticClass:"clickable-icon",attrs:{"src":require("../../../assets/eva_edit-2-outline.svg")},on:{"click":function($event){return _vm.setIsEditing(true)}}}):_c('v-icon',{staticClass:"text-dark",on:{"click":function($event){return _vm.setIsEditing(false)}}},[_vm._v("mdi-close")])],1),_c('span',[_c('img',{staticClass:"clickable-icon",attrs:{"src":require("../../../assets/ci_trash-full.svg")},on:{"click":function($event){return _vm.$emit('remove', {
									type: 'response',
									id: _vm.responseId
								})}}})])])])],1)],1),_vm._t("conditionQueestion")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }