<template>
	<section>
		<article class="course-editor-content__chapters-container">
			<v-row>
				<v-col cols="8">
					<p
						class="course-editor-content__chapters-paragraph"
						v-if="!isEditing"
					>
						{{ content }}
					</p>
					<div v-else>
						<InputTextArea v-model="newContent" />
						<v-btn
							@click="submit"
							class="admin-primary-button"
							:loading="isLoading"
							>Accept</v-btn
						>
					</div>
				</v-col>
				<v-col cols="4">
					<div class="d-flex flex-row justify-end">
						<span
							><v-icon
							class="text-dark"
								@click="
									$emit('changeIndex', {
										type: 'response',
										mark: -1,
										content: response.content,
										questionId: response.question_id,
										sort: response.sort_order,
										responseId: responseId
									})
								"
								>mdi-chevron-up</v-icon
							></span
						>
						<span
							><v-icon
							class="text-dark"
								@click="
									$emit('changeIndex', {
										type: 'response',
										mark: 1,
										content: response.content,
										questionId: response.question_id,
										sort: response.sort_order,
										responseId: responseId
									})
								"
								>mdi-chevron-down</v-icon
							></span
						>
						<span
							><img class="clickable-icon" src="../../../assets/eva_edit-2-outline.svg" v-if="!isEditing"
								@click="setIsEditing(true)">
							<v-icon v-else class="text-dark" @click="setIsEditing(false)"
								>mdi-close</v-icon
							></span
						>
						<span
							><img class="clickable-icon" src="../../../assets/ci_trash-full.svg" @click="
									$emit('remove', {
										type: 'response',
										id: responseId
									})
								"></span
						>
					</div>
				</v-col>
			</v-row>
		</article>
		<slot name="conditionQueestion"></slot>
	</section>
</template>
<script>
import InputTextArea from "@/components/InputTextArea.vue";
import { mapGetters } from "vuex";

export default {
	name: "QuizEditorChapterElementContent",
	components: {
		InputTextArea
	},
	props: {
		question: {
			type: Object,
			require: true
		},
		response: {
			type: Object,
			require: true
		}
	},
	computed: {
		...mapGetters(["isLoading"]),
		content() {
			return this.response.content;
		},
		questionId() {
			return this.question?.id;
		},
		responseId() {
			return this.response?.id;
		},
		responseScore() {
			return 0;
		},
		responseIsCorrect() {
			return this.question?.risk_score === "1.00";
		},
		responseText() {
			return this.content?.responseText;
		}
	},
	data() {
		return {
			rules: {
				required: value => !!value || "Required."
			},
			isEditing: false,
			newContent: this.content
		};
	},
	methods: {
		setIsEditing(val) {
			this.isEditing = val;
		},
		async submit() {
			await this.$emit("save", {
				type: "response",
				content: this.newContent,
				object: {
					question_id: this.response.question_id,
					content: this.response.content,
					help_text: this.response.help_text,
					sort_order: this.response.sort_order,
					explanation: this.response.explanation,
					risk_score: this.response.risk_score,
					custom: this.response.custom,
					organization_id: this.response.organization_id,
					category_id: this.response.category_id
				},
				id: this.response.id
			});
			this.setIsEditing(false);
		}
	}
};
</script>

<style></style>
